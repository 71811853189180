import React from "react"
import { Link } from "gatsby"

const StartFreeTrial = ({ display }) => (
	<div className={display?"start-free-trial start-free-trial-show":"start-free-trial"}>
    <Link to="https://app.adjust.com/betg9vk?label=getapp&redirect=https%3A%2F%2Fapps.apple.com%2Fapp%2Fapple-store%2Fid1249805978%3Fpt%3D99654804%26ct%3DMealPrepPro%20Website%20Get%20App%20Link%26mt%3D8">
      Start your free trial
    </Link>
  </div>
)
	
export default StartFreeTrial
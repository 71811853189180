import React, { useState } from "react"
import { Link } from "gatsby"
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import CookieConsent, { Cookies } from "react-cookie-consent";

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Favicon from "../components/favicon"
import ImageSlider from "../components/imageslider"
import Testimonials from "../components/testimonial"
import StarsTestimonials from "../components/starTestimonials"
import StartFreeTrial from "../components/startFreeTrial"

import ListDot from "../images/list-dot.svg"
import FeedbackStar from "../images/feedback-star.svg"
import HeroBackground from "../images/hero-bg.svg";
import SectionBg  from "../images/section-bg-1.svg";
import MobileSectionBg from "../images/mobile-section-bg.svg"
import RecipesIcon from "../images/recipes-clock.svg"
import PlanIcon from "../images/arrow-pan.svg"
import EnjoyMeal from "../images/enjoy-meal.svg"
import QuoteIcon from "../images/quotes.svg"
import ChartIcon from "../images/market-chart.svg"
import WhyBg from "../images/footer-bg.svg"
import WhyControl from "../images/why-control-icon.svg"
import WhyBowl from "../images/why-bowl-icon.svg";
import WhyChart from "../images/why-chart-icon.svg"
import WhyClock from "../images/why-clock-icon.svg"
import WhyNote from "../images/why-note-icon.svg"
import WhyVeg from "../images/why-vegetable-icon.svg"
import FiveStar from "../images/hidden/five-star.png"
import FaceBookIcon from "../images/facebook-icon.svg"
import InstagramIcon from "../images/instagram-icon.svg"
import AppStoreIcon from "../images/official-appstore-icon.svg"


const IndexPage = () => {
  const [hideOnScroll, setHideOnScroll] = useState(false)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      console.log(currPos.y)
      const isShow = currPos.y < -500
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll],
    false,
    false,
    500
  )
  return (
    <Layout>
      <SEO title="MealPrepPro" description="The #1 Meal Prep app. Feel organized and energized with delicious healthy meal plans customized to your needs. New recipes added every week. Make it easy to feel great and see results that last." subtitle="The #1 Meal Prep app"/>
      <meta name="apple-itunes-app" content="app-id=1249805978" />
      <CookieConsent
        location="bottom"
        buttonText="Okay"
        cookieName="DefaultCookie"
        style={{ background: "#2B3E52" }}
        buttonStyle={{ color: "#fff", background: "#F0593A", padding: "10px 16px 9px", borderRadius: "6px", fontSize: "12px" }}
        expires={150}
      >
        This website uses cookies. By continuing to browse you accept the use of cookies. <a href="https://nibbleapps.com/privacy" target="_blank">Learn more</a>
      </CookieConsent>
      <div className="hero">
      	<div className="container">
      		<h1>Make <span>Healthy</span> The <span>Easy</span> Option</h1>
      		<div className="list">
      			<div className="list-item">
      				<img src={ListDot} alt="feature-1" /> Delicious easy meal plans
      			</div>
      			<div className="list-item">
      				<img src={ListDot} alt="feature-2" /> Personalized to your goals
      			</div>
      			<div className="list-item">
      				<img src={ListDot} alt="feature-3" /> Stay organized and on track
      			</div>
      		</div>
      		<a href="https://app.adjust.com/betg9vk?label=getapp&redirect=https%3A%2F%2Fapps.apple.com%2Fapp%2Fapple-store%2Fid1249805978%3Fpt%3D99654804%26ct%3DMealPrepPro%20Website%20Get%20App%20Link%26mt%3D8">
            <div className="btn">
                Try For Free
            </div>
          </a>
          <div className="feedback-star">
         		<a href="https://app.adjust.com/wmt9mx5?label=reviews&redirect=https%3A%2F%2Fapps.apple.com%2Fapp%2Fapple-store%2Fid1249805978%3Fmt%3D8#see-all/reviews?pt%3D99654804%26ct%3DMealPrepPro%20Website%20Get%20App%20Link%26mt%3D8"><img src={FeedbackStar} alt="feedback-start" /></a>
         	</div>
         	<div className="reviews">
         		<div className="review-text">
         			<strong>3,246</strong> reviews and counting!
         		</div>
         		<a href="https://app.adjust.com/betg9vk?label=getapp&redirect=https%3A%2F%2Fapps.apple.com%2Fapp%2Fapple-store%2Fid1249805978%3Fpt%3D99654804%26ct%3DMealPrepPro%20Website%20Get%20App%20Link%26mt%3D8"><Image name="new-app-we-love-dark.png" alt="Apple - New Apps We Love"/></a>
         	</div>
      	</div>
      	<div className="hero-background">
      		<img src={HeroBackground} alt="hero-bg" />
      	</div>
      </div>
      <div className="recipes">
        <div className="recipes-bg desktop-show">
          <img src={SectionBg} alt="recipes section background" />
        </div>
        <div className="recipes-bg desktop-hidden">
          <img src={MobileSectionBg} alt="recipes section background" />
        </div>
        <div className="recipes-content">
          <div className="text">
            <img src={RecipesIcon} alt="recipes-clock" />
            <h2><span>New</span> Recipes Every Week</h2>
            <p>Budget friendly recipes using everyday ingredients. Sort by prep time, filter to suit your mood. Delicious food in minutes.</p>
          </div>
          <div className="images">
            <ImageSlider />
          </div>
        </div>
      </div>
      <Testimonials />
      <section className="plan">
        <div className="plan-bg">
          <img src={SectionBg} alt="plan section background" />
        </div>
        <div className="plan-content">
          <img className="plan-icon" src={PlanIcon} alt="plan icon" />
          <h2>Reach Your Goal With A <span>Personalized</span> Plan</h2>
          <p>Portions to match your calorie needs. Choose your plan or set your own macros. Low-Carb, High-Protein, Mediterranean, Keto, Vegan and lots more.</p>
          <div className="plan-images">
            <div className="left-images">
              <Image name="vegan.png" alt="Vegan meal prep"/>
              <Image name="keto.png" alt="Keto meal prep"/>
            </div>
            <div className="middle-image">
              <Image name="mealpreppro-protein-meal-prep.png" alt="High Protein Meal Prep"/>
            </div>
            <div className="right-images">
              <Image name="mediterranean.png" alt="Mediterranean meal prep"/>
              <Image name="low-carb.png" alt="Low-Carb meal prep"/>
            </div>
          </div>
        </div>
      </section>
      <section className="meal">
        <div className="container">
          <div className="col">
            <img className="icon" src={EnjoyMeal} alt="enjoy-meal" />
            <h2>Enjoy Meals <span>Together</span></h2>
            <p>Add a partner to your plan. Set their goals and enjoy meals that meet both of your needs.</p>
            <div className="card">
              <img className="meal-quote" src={QuoteIcon} alt="meal-quote" />
              <p>My wife saw how good my meals were so I added her to the plan. Now we can share meals on the days that suit us. The app even tells you exactly how to portion for your partners calories</p>
              <h6>John</h6>
              <img src={FiveStar} alt="5 Star Review" />
            </div>
          </div>
          <div className="col">
            <Image name="enjoy-meal-prep-app.png" alt="MealPrepPro - Partner Meal Prep" />
          </div>
        </div>
      </section>
      <section className="organize">
        <div className="organize-bg">
          <img src={SectionBg} alt="organize section background" />
        </div>
        <div className="container">
          <div className="col">
            <Image name="mealpreppro-app-mockup.png" alt="MealPrepPro App Mockup" />
          </div>
          <div className="col">
            <div className="content">
              <img src={ChartIcon} alt="market-chart" className="market-chart" />
              <h2>Stay <span>Organized</span></h2>
              <h2>Feel Energized</h2>
              <div className="list">
                <p className="list-item">
                  <img src={ListDot} alt="feature-1" /> Personalize your plan
                </p>
                <p className="list-item">
                  <img src={ListDot} alt="feature-2" /> Choose your meals
                </p>
                <p className="list-item">
                  <img src={ListDot} alt="feature-3" /> Track calories & macros
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="get-start">
        <div className="container">
          <h2><span>It's Easy</span> To Get Started</h2>
          <StarsTestimonials />
        </div>
      </section>
      <div className="why">
        <div className="why-bg">
          <img src={WhyBg} alt="why-mealpreppro" />
        </div>
        <div className="container">
          <h2>Why <span>MealPrepPro?</span></h2>
          <div className="features">
            <div className="feature">
              <img src={WhyControl} alt="More personalization than ever" />
              <p>More personalization than ever</p>
            </div>
            <div className="feature">
              <img src={WhyBowl} alt="New recipes every week" />
              <p>New recipes every week</p>
            </div>
            <div className="feature">
              <img src={WhyNote} alt="Smart grocery list" />
              <p>Smart grocery list, only buy what you need</p>
            </div>
            <div className="feature">
              <img src={WhyClock} alt="Save time and money" />
              <p>Save time and money</p>
            </div>
            <div className="feature">
              <img src={WhyChart} alt="Set and track your health goals" />
              <p>Set and track your health goals</p>
            </div>
            <div className="feature">
              <img src={WhyVeg} alt="Take the thinking out of healthy eating" />
              <p>Take the thinking out of healthy eating </p>
            </div>
          </div>
            <a href="https://app.adjust.com/betg9vk?label=getapp&redirect=https%3A%2F%2Fapps.apple.com%2Fapp%2Fapple-store%2Fid1249805978%3Fpt%3D99654804%26ct%3DMealPrepPro%20Website%20Get%20App%20Link%26mt%3D8"><Image name="new-app-we-love.png" alt="Apple - New Apps We Love" /></a>
        </div>
      </div>
      <footer className="home-footer">
        <div className="container">
          <div className="flex-row menus">
            <a href="https://nibbleapps.com/terms">Terms of Service</a>
            <a href="https://nibbleapps.com/privacy">Privacy Policy</a>
            <a href="https://help.mealpreppro.com/">Got a Question?</a>
          </div>
          <div className="flex-row social">
            <a href="https://www.facebook.com/groups/mealpreppro" target="_blank"><img className="facebook" src={FaceBookIcon} alt="facebook" /></a>
            <a href="https://www.instagram.com/mealprepproapp" target="_blank"><img className="instagram" src={InstagramIcon} alt="instagram" /></a>
          </div>
        </div>
        <div className="appstore">
          <a href="https://app.adjust.com/betg9vk?label=getapp&redirect=https%3A%2F%2Fapps.apple.com%2Fapp%2Fapple-store%2Fid1249805978%3Fpt%3D99654804%26ct%3DMealPrepPro%20Website%20Get%20App%20Link%26mt%3D8">
            <img src={AppStoreIcon} alt="appstore" />
          </a>
        </div>
      </footer>
      <StartFreeTrial display={hideOnScroll} />
    </Layout>
  )
}

export default IndexPage

import React, { Component } from 'react'
import { Link } from "gatsby"
import Slider from "react-slick";
import Image from "./image"

import RightArrow from "../images/right-arrow.svg"
import LeftArrow from "../images/left-arrow.svg"
import QuoteIcon from "../images/quotes.svg"
import FiveStar from "../images/hidden/five-star.png"


function SampleNextArrow(props) {
  const { className, style} = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none"}}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style} = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none"}}
    />
  );
}


class StarTestimonials extends Component {
	constructor(props) {
		super(props)
		this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
	}
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
	
	render(){
		const settings = {
			infinite: true,
			autoPlay: true,
			slidesToShow: 1,
			speed: 500,
			nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
	  };
		return(
			<section id="testimonials" className="testimonials">
				<div className="container">
					<button className="left-arrow" onClick={this.previous}>
           <img src={LeftArrow} alt="left-arrow" />
	        </button>
	        <button className="right-arrow" onClick={this.next}>
	          <img src={RightArrow} alt="right-arrow" />
	        </button>
					<Slider {...settings} ref={c => (this.slider = c)}>
					<div className="card">
							<img src={QuoteIcon} alt="quote-8" />
							<p>
								I can do this now! Thanks for showing me how. This alleviates a stress that comes from last minute procrastination. 

							</p>
							<h3 className="name">
								AnotherGRL
							</h3>
							<div className="star">
								<img src={FiveStar} alt="meal-prep-pro-review" />
							</div>
						</div>
						<div className="card">
							<img src={QuoteIcon} alt="quote-9" /> 
							<p>
								After trying a ton of apps for meal planning and cooking, this app has changed everything about my diet and overall health. I am an extremely busy person so meal prepping and eating healthy can be time consuming. Meal Prep Pro is so easy to use. You don’t have to think. 

							</p>
							<h3 className="name">
								MBH234
							</h3>
							<div className="star">
								<img src={FiveStar} alt="meal-prep-pro-review1" />
							</div>
						</div>
						<div className="card">
							<img src={QuoteIcon} alt="quote-10" />
							<p>
								For a girl who loathes prepping this app is The Best! From meals choices to meal portions to shopping list. It makes it easy for me to get on the prepping bandwagon the correct way.	 

							</p>
							<h3 className="name">
								Chance7383
							</h3>
							<div className="star">
								<img src={FiveStar} alt="meal-prep-pro-review2" />
							</div>
						</div>
						<div className="card">
							<img src={QuoteIcon} alt="quote-11" />
							<p>
								Has definitely help me better plan out my week. I love the fact that I can switch or skip recipes depending on how much time I have. 

							</p>
							<h3 className="name">
								Jasmine B. S. 
							</h3>
							<div className="star">
								<img src={FiveStar} alt="meal-prep-pro-review3" />
							</div>
						</div>
						<div className="card">
							<img src={QuoteIcon} alt="quote-12" />
							<p>
								This app is incredible! It saved my life by making it so well organised… I’ve lost weight and felt the best I ever have. 

							</p>
							<h3 className="name">
								Pjnolan4524
							</h3>
							<div className="star">
								<img src={FiveStar} alt="meal-prep-pro-review4" />
							</div>
						</div>
						<div className="card">
							<img src={QuoteIcon} alt="quote-13" />
							<p>
								This app is totally customizable to your preferences and takes all of the guesswork out. I don’t count calories or watch macros because the app does it all for me based on my current weight and goals. 

							</p>
							<h3 className="name">
								Lauren1630
							</h3>
							<div className="star">
								<img src={FiveStar} alt="meal-prep-pro-review5" />
							</div>
						</div>
						<div className="card">
							<img src={QuoteIcon} alt="quote-14" />
							<p>
								I’ve always eaten healthy, but I was always wrong in terms of how much I ate, how to combine food, not eating enough carbs, etc. Since I’m using this app, I organize meals much easier and I am certain that I’m doing it right.

							</p>
							<h3 className="name">
								Tarahis
							</h3>
							<div className="star">
								<img src={FiveStar} alt="meal-prep-pro-review6" />
							</div>
						</div>
						<div className="card">
							<img src={QuoteIcon} alt="quote-15" />
							<p>
								I have done some similar apps before but nothing quite like this one. The shopping list makes it super easy and I have like almost no waste which is awesome. I have been recommending it to anyone who will listen.

							</p>
							<h3 className="name">
								Raven
							</h3>
							<div className="star">
								<img src={FiveStar} alt="meal-prep-pro-review7" />
							</div>
						</div>
					</Slider>
				</div>
			</section>
		)
	}
}

export default StarTestimonials
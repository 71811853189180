import React, { Component } from 'react'
import { Link } from "gatsby"
import Slider from "react-slick";

import Image from "./image"

export default class ImageSlider extends Component {
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "30%",
      slidesToShow: 1,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 3000,
      dots: false,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            centerPadding: "20%",
          }
        },
        {
          breakpoint: 1200,
          settings: {
            centerPadding: "10%",
          }
        },
      ]
    };
    return (
      <div className="slider-container">
        <Slider {...settings}>
          <div className="slider-image">
            <Image name="13-slow-cooker-chicken-tortilla-soup.jpg" alt="Slow Cooker Chicken Tortilla Soup" title="Slow Cooker Chicken Tortilla Soup"/>
          </div>
          <div className="slider-image">
            <Image name="7-low-carb-bbq-chicken-meal-prep.jpg" alt="Low-Carb BBQ Chicken Meal Prep" title="Low-Carb BBQ Chicken Meal Prep"/>
          </div>
          <div className="slider-image">
            <Image name="8-mexican-style-egg-muffins.jpg" alt="Mexican Style Egg Muffins" title="Mexican Style Egg Muffins"/>
          </div>
          <div className="slider-image">
            <Image name="12-healthy-beef-stroganoff.jpg" alt="Healthy Beef Stroganoff" title="Healthy Beef Stroganoff"/>
          </div>
          <div className="slider-image">
            <Image name="5-carne-asada-salad.jpg" alt="Carne Asada Salad" title="Carne Asada Salad"/>
          </div>
          <div className="slider-image">
            <Image name="1-vegan-buddha-bowl.jpg" alt="Vegan Buddha Bowl" title="Chickpea Tofu Bowl"/>
          </div>
          <div className="slider-image">
            <Image name="16-chickpea-kale-caesar.jpg" alt="Chickpea Kale Caesar" title="Chickpea Kale Caesar"/>
          </div>
          <div className="slider-image">
            <Image name="14-salmon-greens-grains-bowl.jpg" alt="Salmon Greens and Grains Bowl" title="Salmon Greens and Grains Bowl"/>
          </div>
          <div className="slider-image">
            <Image name="2-meal-prep-snacks.jpg" alt="Meal Prep Snacks" title="Mediterranean Snack Box"/>
          </div>
          <div className="slider-image">
            <Image name="3-blueberry-oatmeal.jpg" alt="Blueberry Oatmeal" title="Blueberry Oatmeal"/>
          </div>
          <div className="slider-image">
            <Image name="17-peanut-butter-toast-with-blueberries.jpg" alt="Peanut Butter Toast with Blueberries" title="Peanut Butter Toast with Blueberries"/>
          </div>
          <div className="slider-image">
            <Image name="4-apricot-oatmeal-energy-bites.jpg" alt="Apricot Oatmeal Energy Bites" title="Apricot Oatmeal Energy Bites"/>
          </div>
          <div className="slider-image">
            <Image name="10-peanut-butter-cookies.jpg" alt="4-Ingredient Peanut Butter Cookies" title="4-Ingredient Peanut Butter Cookies"/>
          </div>
          <div className="slider-image">
            <Image name="11-green-energy-smoothie.jpg" alt="Green Energy Smoothie" title="Green Energy Smoothie"/>
          </div>
          <div className="slider-image">
            <Image name="9-salmon-protein-pot.jpg" alt="Salmon Protein Pot" title="Salmon Protein Pot"/>
          </div>
          <div className="slider-image">
            <Image name="15-curry-chicken-salad-wrap.jpg" alt="Curry Chicken Salad Wrap" title="Curry Chicken Salad Wrap"/>
          </div>
          <div className="slider-image">
            <Image name="6-keto-lasagna.jpg" alt="Keto Lasagna" title="Keto Lasagna"/>
          </div>
        </Slider>
      </div>
    );
  }
}
import React, { Component } from 'react'
import { Link } from "gatsby"
import Slider from "react-slick";

import RightArrow from "../images/right-arrow.svg"
import LeftArrow from "../images/left-arrow.svg"
import QuoteIcon from "../images/quotes.svg"


function SampleNextArrow(props) {
  const { className, style} = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none"}}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style} = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none"}}
    />
  );
}


class Testimonials extends Component {
	constructor(props) {
		super(props)
		this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
	}
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
	
	render(){
		const settings = {
			infinite: true,
			autoPlay: true,
			slidesToShow: 1,
			speed: 500,
			nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
	  };
		return(
			<section id="testimonials" className="testimonials">
				<div className="container">
					<button className="left-arrow" onClick={this.previous}>
           <img src={LeftArrow} alt="left-arrow" />
	        </button>
	        <button className="right-arrow" onClick={this.next}>
	          <img src={RightArrow} alt="right-arrow" />
	        </button>
					<Slider {...settings} ref={c => (this.slider = c)}>
						<div className="card">
							<img src={QuoteIcon} alt="quote-1" /> 
							<p>
								The recipes are easy, SUPER tasty, lots of variety and whole food healthy. The app is easy to use and has a lot of flexibility so you can view and plan things in ways that meet your specific needs. 

							</p>
							<h3 className="name">
								Kdunbar53
							</h3>
						</div>
						<div className="card">
							<img src={QuoteIcon} alt="quote-2" />
							<p>
								I started at a slightly overweight 87.2kg and religiously sticking to a 2,000 calorie diet plan I’m now at 82.2kg after 3 weeks. 

							</p>
							<h3 className="name">
								Duffy643
							</h3>
						</div>
						<div className="card">
							<img src={QuoteIcon} alt="quote-3" />
							<p>
								The app “sneaks up” on healthy eating. If you’re like me, and theoretically value healthy food but detest “dieting”, this app is for you. The flex plans can also help you gently introduce plant-based eating. 

							</p>
							<h3 className="name">
								Club40watt
							</h3>
						</div>
						<div className="card">
							<img src={QuoteIcon} alt="quote-4" />
							<p>
								 I could not be more happy with this app. From the variety of meals, to the simplicity of recipes. The way the app calculates specific macro and caloric requirements and gives an output of delicious recipes and a super simple shopping list is beyond compare.
							</p>
							<h3 className="name">
								AdamKGbrewer
							</h3>
						</div>
						<div className="card">
							<img src={QuoteIcon} alt="quote-5" />
							<p>
								I’ve been consistently losing weight since using this meal prep guide regularly. It’s the easiest app I’ve ever used and provides everything I knew I needed, but could never master, for meal prep.

							</p>
							<h3 className="name">
								Dremenyi
							</h3>
						</div>
						<div className="card">
							<img src={QuoteIcon} alt="quote-6" />
							<p>
								I’ve lost weight and volume, I feel full of energy and I’m saving money. Plus your recipes are so tasty ( I don’t want to go to McDonalds anymore!). Thank you.

							</p>
							<h3 className="name">
								Tarahis
							</h3>
						</div>
						<div className="card">
							<img src={QuoteIcon} alt="quote-7" />
							<p>
								I’ve tried so many meal prep plans and they’ve all been bland or lacking the proper tracking. MPP has it all. Calculates you calories for you with a tremendous amount of variety in their recipes. Plus the grocery list, they do all the work for you. 

							</p>
							<h3 className="name">
								Bee Rita
							</h3>
						</div>
					</Slider>
				</div>
			</section>
		)
	}
}

export default Testimonials